<template>
	<div class="app-container app-container-true staffHeader">
		<Navbar showType="1" />
		<template>
			<!-- <div class="titlebox">
				<div class="title" v-if="!staffinfo.staffBasicInfoDto?.subName">
					{{ jobinfo.staffBasicJobDetailDto && jobinfo.staffBasicJobDetailDto.orgName }}-{{
						staffinfo.staffBasicInfoDto && staffinfo.staffBasicInfoDto.name
					}}
				</div>
				<div class="title" v-if="staffinfo.staffBasicInfoDto?.subName">
					{{ jobinfo.staffBasicJobDetailDto && jobinfo.staffBasicJobDetailDto.orgName }}-{{
						staffinfo.staffBasicInfoDto && staffinfo.staffBasicInfoDto.name
					}}
					({{ staffinfo.staffBasicInfoDto && staffinfo.staffBasicInfoDto.subName }})
				</div>
				<div class="logout">
					<el-dropdown class="avatar-container" trigger="click">
						<div class="avatar-wrapper">
							<img :src="staffinfo.staffBasicInfoDto?.avatarUrl" class="user-avatar" />
							<i class="el-icon-caret-bottom" />
						</div>
						<el-dropdown-menu slot="dropdown" class="user-dropdown">
							<el-dropdown-item>
								<span style="display: block" @click="dialogFormVisible = true">修改密码</span>
							</el-dropdown-item>
							<el-dropdown-item divided>
								<span style="display: block" @click="logout">退出登录</span>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<el-dialog title="修改密码" :visible.sync="dialogFormVisible" width="30%" @close="handleChangPwdClose" center>
						<el-form :model="form" label-position="right" :rules="changePwdRule" ref="form">
							<el-form-item prop="oldPassword" label="旧密码" :label-width="formLabelWidth">
								<el-col :span="24">
									<el-input v-model="form.oldPassword" placeholder="当前密码" type="password" autocomplete="off"></el-input>
								</el-col>
							</el-form-item>
							<el-form-item prop="newPassword" label="新密码" :label-width="formLabelWidth">
								<el-col :span="24">
									<el-input
										v-model="form.newPassword"
										placeholder="请输入8-20位字符，必须包含字母和数字"
										type="password"
										autocomplete="off"
									></el-input>
								</el-col>
							</el-form-item>
							<el-form-item prop="againPassword" label="确认密码" :label-width="formLabelWidth">
								<el-col :span="24">
									<el-input v-model="form.againPassword" type="password" placeholder="请再次输入密码" autocomplete="off">
									</el-input>
								</el-col>
							</el-form-item>
						</el-form>
						<div slot="footer" class="dialog-footer">
							<el-button @click="handleChangPwdClose">取消</el-button>
							<el-button type="primary" @click="changepwd">确定</el-button>
						</div>
					</el-dialog>
				</div>
			</div> -->
			<div class="viewbox">
				<el-row class="badgeContainer" justify="space-between;" type="flex">
					<el-col>
						<el-row type="flex">
							<!-- <el-col
								:span="3"
								@click.native="$router.push('/staff/approval')"
								:class="{ badge: true, active: $route.path === '/staff/approval' }"
								v-if="permissionControl('StaffApproval')"
							>
								<div class="imagebox">
									<svg-icon class="svg" icon-class="aduit"></svg-icon>
									<div class="badgeTips" v-if="existApprovalPending"></div>
								</div>
								<div class="word">审批</div>
							</el-col> -->
							<!-- <el-col
								:span="3"
								@click.native="$router.push('/staff/talent_planning')"
								:class="{ badge: true, active: $route.path === '/staff/talent_planning' }"
							>
								<div class="imagebox">
									<svg-icon class="svg" icon-class="plan"></svg-icon>
									<div class="badgeTips" v-if="numberObj.personalPlanCount != 0"></div>
								</div>
								<div class="word">人力规划</div>
							</el-col>
							<el-col
								:span="3"
								@click.native="$router.push('/staff/match_position')"
								:class="{ badge: true, active: $route.path === '/staff/match_position' }"
							>
								<div class="imagebox">
									<svg-icon class="svg" icon-class="pipei"></svg-icon>
									<div class="badgeTips" v-if="numberObj.assessCount != 0"></div>
								</div>
								<div class="word">人岗匹配</div>
							</el-col>
							<el-col
								:span="3"
								@click.native="$router.push('/staff/recruit')"
								:class="{ badge: true, active: $route.path === '/staff/recruit' }"
							>
								<div class="imagebox">
									<svg-icon class="svg" icon-class="zhaopin"></svg-icon>
									<div class="badgeTips" v-if="numberObj.interviewCount != 0"></div>
								</div>
								<div class="word">招聘调配</div>
							</el-col>
							<el-col
								:span="3"
								@click.native="$router.push('/staff/achievements')"
								:class="{ badge: true, active: $route.path === '/staff/achievements' }"
							>
								<div class="imagebox">
									<svg-icon class="svg" icon-class="jixiao"></svg-icon>
									<div class="badgeTips" v-if="numberObj.performanceCount != 0"></div>
								</div>
								<div class="word">绩效</div>
							</el-col>
							<el-col
								:span="3"
								@click.native="$router.push('/staff/momey')"
								:class="{ badge: true, active: $route.path === '/staff/momey' }"
							>
								<div class="imagebox">
									<svg-icon class="svg" icon-class="xinchou"></svg-icon>
								</div>
								<div class="word">薪酬</div>
							</el-col>
							<el-col
								:span="3"
								@click.native="$router.push('/staff/trainMange')"
								:class="{ badge: true, active: $route.path === '/staff/trainMange' }"
							>
								<div class="imagebox">
									<svg-icon class="svg" icon-class="train"></svg-icon>
									<div class="badgeTips" v-if="numberObj.trainingCount != 0"></div>
								</div>
								<div class="word">教育培训</div>
							</el-col>
							<el-col
								:span="3"
								@click.native="$router.push('/staff/corehuman')"
								:class="{ badge: true, active: $route.path === '/staff/corehuman' }"
							>
								<div class="imagebox">
									<svg-icon class="svg" icon-class="hexin"></svg-icon>
									<div class="badgeTips" v-if="numberObj.coreCount != 0"></div>
								</div>
								<div class="word">核心人力</div>
							</el-col>
							<el-col :span="3" @click.native="$router.push('/staff/cc')" :class="{ badge: true, active: $route.path === '/staff/cc' }">
								<div class="imagebox">
									<svg-icon class="svg" icon-class="ccinformation"></svg-icon>
									<div class="badgeTips" v-if="numberObj.messageCount != 0"></div>
								</div>
								<div class="word">抄送信息</div>
							</el-col> -->
							<!-- <el-col
								:span="3"
								@click.native="$router.push('/staff/info')"
								:class="{ badge: true, active: $route.path === '/staff/info' }"
								v-if="permissionControl('StaffInfo')"
							>
								<svg-icon class="svg" icon-class="info"></svg-icon>
								<div class="word">信息</div>
							</el-col> -->
						</el-row>
					</el-col>
				</el-row>
			</div>
		</template>
		<div class="infoContanier">
			<transition name="bounce">
				<router-view ref="child" :staffId="staffId"></router-view>
			</transition>
		</div>
	</div>
</template>
<script>
import { Logout, getCost } from '@/api/common';
import * as util from '@/utils/util';
import md5 from 'js-md5';
import { getStaffInfoDetail, getStaffJobInfoDetail } from '../../api/workbench';
import { getApprovalList } from '../../api/staffSystem';
import { getUserInfo, changePwd } from '@/api/loginInfo';
import { Mode } from './const';
import { EXISTAPPROVALPENDING, ACHIEVEMENTSLOADING } from '../../store/types';
import { Navbar } from '../../layout/components';
const Application = '申请审核';

export default {
	components: { Navbar },
	data() {
		const validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.form.newPassword) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};

		const equalOld = (rule, value, callback) => {
			if (value === this.form.oldPassword) {
				callback(new Error('新密码不能和旧密码相同'));
			} else if (!value.match(/^(?![^a-zA-Z]+$)(?!\D+$)/)) {
				callback(new Error('请输入8-20位字符，必须包含字母和数字'));
			} else if (value.length < 8 || value.length > 20) {
				callback(new Error('请输入8-20位字符，必须包含字母和数字'));
			} else {
				callback();
			}
		};

		return {
			Mode,
			Application,
			dialogVisible: false,
			dialogFormVisible: false,
			formLabelWidth: '90px',
			activeNav: '',
			dialogAction: '',
			activeName: 'first',
			staffId: '',
			staffinfo: {},
			jobinfo: {},
			numberObj: {
				personalPlanCount: 0,
				assessCount: 0,
				interviewCount: 0,
				performanceCount: 0,
				coreCount: 0,
				trainingCount: 0,
				messageCount: 0
			},
			form: {
				oldPassword: '',
				newPassword: '',
				againPassword: ''
			},
			existPending: false,
			changePwdRule: {
				oldPassword: [{ required: true, message: '请输入当前密码', trigger: 'blur' }],
				newPassword: [
					{ required: true, message: '请输入新密码', trigger: 'blur' },
					{ validator: equalOld, trigger: 'blur' }
				],
				againPassword: [{ required: true, validator: validatePass2, trigger: 'blur' }]
			}
		};
	},
	async mounted() {
		this.queryBadge();
		getUserInfo({ request: {} }).then((res) => {
			this.staffId = res.staffId;
			this.$store.commit('app/SET_COMPANYPRIN', res.companyPrin);
			this.$store.commit('app/SET_ORGIDS', res.orgIds);
			this.$store.commit('app/SET_STAFFID', res.staffId);
			sessionStorage.setItem('userInfo', JSON.stringify(res));
			getStaffInfoDetail({ request: { id: this.staffId } }).then((res) => {
				// console.log('员工信息：', res);
				this.staffinfo = res;
			});
			getStaffJobInfoDetail({ request: { id: this.staffId } }).then((res) => {
				// console.log('员工工作信息：', res);
				this.jobinfo = res;
			});
		});
	},
	computed: {
		existApprovalPending: {
			get() {
				return this.$store.state.approval.existApprovalPending;
			},
			set(val) {
				this.$store.commit(EXISTAPPROVALPENDING, val);
			}
		},
		achievementsloading: {
			get() {
				return this.$store.state.approval.achievementsloading;
			},
			set(val) {
				this.$store.commit(ACHIEVEMENTSLOADING, val);
			}
		}
	},
	methods: {
		async queryBadge() {
			return getApprovalList({ pageSize: 10, page: 1, queryType: 2 }).then((res) => {
				if (res.totalNum > 0) {
					this.existApprovalPending = true;
				} else {
					this.existApprovalPending = false;
				}
				getCost({}).then((res1) => {
					if (res1._responseStatusCode == 0) {
						// eslint-disable-next-line guard-for-in
						for (const key in res1) {
							for (const key1 in this.numberObj) {
								// eslint-disable-next-line no-eq-null
								if (key === key1 && res1[key] != null) {
									this.numberObj[key1] = res1[key];
								}
							}
						}
					}
				});
			});
		},
		applyApprovalSuccess() {
			this.$refs.child.getPageData();
		},
		openDialog(action) {
			this.dialogAction = action;
			if (this.dialogAction === Application) {
				this.$refs.child.showApplyApproval(Mode.Add);
			}
		},
		logout() {
			this.$confirm('您是否确认退出登录?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					await Logout({ request: {} });
					util.delCookie('token');
					this.$router.push('/login');
				})
				.catch(() => {});
		},
		changepwd() {
			this.$refs['form'].validate((valid) => {
				if (valid) {
					changePwd({
						request: {
							oldPassword: md5(this.form.oldPassword),
							newPassword: md5(this.form.newPassword),
							againPassword: md5(this.form.againPassword)
						}
					}).then((res) => {
						if (res.code == 0) {
							this.$message.success('密码修改成功');
							this.handleChangPwdClose();
							// Logout({ request: {} });
							// util.delCookie("token");
							// this.$router.push("/login");
						}
					});
				}
			});
		},
		handleChangPwdClose() {
			this.$refs['form'].clearValidate();
			this.$refs['form'].resetFields();
			this.dialogFormVisible = false;
		}
	}
};
</script>
<style lang="scss">
.staffHeader {
	.navbar {
		display: inline-flex;
		width: 100%;
		align-items: center;
		justify-content: space-between;
		height: 68px;
		// background: #4a8df0;
		// color: #fff;
	}
}
.input-field {
	display: flex;
	align-items: center;

	.field {
		flex: 5;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.8s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.infoContanier .infoTab .el-tabs__header {
	background: #fff;
	// padding: 0 20px;
	padding: 0 20px;
	margin: 0 15px;
}

.infoContanier .secondTabs .el-tabs__header {
	margin: 10px 0;
	padding: 0;
}

.approval-form {
	.select-form-item {
		width: 50%;
	}

	.input-form-item {
		width: 70%;

		&.el-input-number .el-input__inner {
			text-align: left;
		}
	}

	.form-item-tip {
		margin: 0;
	}
}

.input-form-item {
	width: 80%;
}

.dialog-footer {
	text-align: center;
}
</style>

<style lang="scss" scoped>
.assessBox .app-container .viewbox .level_box_now {
	font-weight: bold;
}

.tab-panel {
	background: #fff;
}

.app-container {
	color: #484848;
	padding: 0;
	height: 100vh;
	background-color: #f6f7fb;

	.titlebox {
		text-align: center;
		line-height: 17px;
		background: #fff;
		font: bold 18px/25px 'Microsoft YaHei';
		padding: 14px 40px;

		.logout {
			position: absolute;
			top: 7px;
			font-size: 16px;
			right: 25px;
			font-weight: normal;
			cursor: pointer;

			.avatar-container {
				margin-right: 30px;

				.avatar-wrapper {
					// margin-top: 5px;
					position: relative;

					.user-avatar {
						cursor: pointer;
						width: 40px;
						height: 40px;
						border-radius: 50%;
					}

					.el-icon-caret-bottom {
						cursor: pointer;
						position: absolute;
						right: -20px;
						top: 12px;
						font-size: 16px;
					}
				}
			}
		}
	}

	.viewbox {
		padding: 0px;
		margin: 10px;

		.svg-icon {
			width: 60px;
			height: 60px;
		}
	}

	.badgeContainer {
		// padding: 20px;
	}

	.infoContanier {
		height: calc(100% - 198px);
		padding: 0px 20px;
		position: relative;
	}

	.active::after {
		content: ' ';
		position: absolute;
		border-bottom: 25px solid #fff;
		border-right: 25px solid transparent;
		border-left: 25px solid transparent;
		left: 8.8%;
		left: 50%;
		bottom: -45px;
		transform: translateX(-50%);
	}

	.infoContanier .infoTab .el-tabs__header {
		background: #fff;
		// padding: 0 20px;
	}

	.badge {
		// width: 70px;
		height: 70px;
		margin: 5px 0px;
		display: inline-block;
		// border-radius: 50%;
		color: #c5cdda;
		flex: 1;
		// border: 1px solid #c5cdda;
		text-align: center;
		font-size: 12px;
		box-sizing: border-box;
		position: relative;

		.word {
			margin-top: 8px;
		}

		i {
			color: #c5cdda;
			font-size: 30px;
			margin-top: 10px;
		}

		.imagebox {
			margin: 0 auto;
			width: 60px;
			position: relative;
		}

		.badgeTips {
			width: 10px;
			height: 10px;
			border: 1px solid #fff;
			background: #f56c6c;
			border-radius: 50%;
			position: absolute;
			top: 5px;
			right: -10px;
			line-height: 5px;
		}
	}
}
</style>
